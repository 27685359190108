import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import {
  MeetingProvider,
  lightTheme
} from 'amazon-chime-sdk-component-library-react';

import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyBpU_oJOnxU26i-IEqjP_I77u7sCof5xYA",
  authDomain: "mixrpremium.firebaseapp.com",
  projectId: "mixrpremium",
  storageBucket: "mixrpremium.appspot.com",
  messagingSenderId: "928865657865",
  appId: "1:928865657865:web:fe0b04a9da7a917afc04d8",
  measurementId: "G-HTCVCCKRX5"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
          <App />
      </MeetingProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
