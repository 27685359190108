import React from 'react'
import User from './User'
import '../styles/styles.scss'

export default function Room({ users, roomId, theme, newRoomNum, newRoom }) {

    function compare(a, b) {
        if (a.date < b.date) {
            return -1;
        }
        if (a.date > b.date) {
            return 1;
        }
        return 0;
    }


    if (!!users && users.length > 0) {

        users.sort(compare)

        return (
            <div
                className='room'
                style={{ backgroundImage: `url(${theme.background})` }}
                onClick={() => { newRoom(roomId) }}
            >
                <div className='inner'>
                    <h2>{`Room ${roomId}`}</h2>
                    {users.map(function (user, index, array) {
                        return <User user={user} key={index} />
                    })
                    }

                </div>
            </div>
        )
    } else {
        return (
            <div
                className='room'
                style={{ backgroundImage: `url(${theme.background})` }}
                onClick={() => { newRoom(newRoomNum, 1) }}
            >
                <div className='inner'>
                    <h2>{`New Room`}</h2>
                </div>
            </div>
        )
    }

}
