import React, { Component } from 'react'

import firebase from 'firebase'

import User from './User'
import Room from './Room'
import VideoRoom from './VideoRoom'

var images = [
    "https://images.mixronline.com/images/2021/04/30/row-1-col-2.png",
    "https://images.mixronline.com/images/2021/04/30/row-1-col-1.png",
    "https://images.mixronline.com/images/2021/04/30/row-8-col-2.png",
    "https://images.mixronline.com/images/2021/04/30/row-8-col-1.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-11.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-10.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-9.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-8.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-7.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-6.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-5.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-4.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-3.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-2.png",
    "https://images.mixronline.com/images/2021/04/30/row-7-col-1.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-11.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-10.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-9.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-8.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-7.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-6.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-5.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-4.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-2.png",
    "https://images.mixronline.com/images/2021/04/30/row-6-col-3.png",
]

const themes = [
    { label: 'Beach', logo: 'https://images.mixronline.com/images/2021/04/24/greenBeachLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/greenBeach.png', color: '#63A47C' },
    { label: 'Alien', logo: 'https://images.mixronline.com/images/2021/04/24/alienLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/alien-1.png', color: '#FF9B00' },
    { label: 'Cave', logo: 'https://images.mixronline.com/images/2021/04/24/cave1Logo.png', background: 'https://images.mixronline.com/images/2021/04/24/The-Cave.png', color: '#EF8303' },
    { label: 'Moon', logo: 'https://images.mixronline.com/images/2021/04/24/moonLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/moonSpace.png', color: '#F464EA' },
    { label: 'Clouds', logo: 'https://images.mixronline.com/images/2021/04/24/coludlogo2.png', background: 'https://images.mixronline.com/images/2021/04/24/clouds.png', color: '#F5607F' },
    { label: 'Mars', logo: 'https://images.mixronline.com/images/2021/04/24/marsLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/mars.png', color: '#D22C12' },
    { label: 'Sunset', logo: 'https://images.mixronline.com/images/2021/04/24/lakeSunsetLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/redsunset-1.png', color: '#E4405A' },
    { label: 'Mountains', logo: 'https://images.mixronline.com/images/2021/04/24/mountainLogo.png', background: 'https://images.mixronline.com/images/2021/04/24/mountains.png', color: '#43ABA2' },

]


export default class RoomsPage extends Component {

    constructor(props) {
        super(props);
        this.mixrId = '123'

        // Don't call this.setState() here!
        this.state = { rooms: {}, newRoomNum: this.randRoomNumber() };

        this.newRoom = this.newRoom.bind(this);

        this.db = firebase.database().ref(this.mixrId);

        this.id = this.uuidv4();
        this.name = 'User' + Math.floor(1000 + Math.random() * 9000);
        this.img = images[Math.floor(0 + Math.random() * images.length)];
        this.room = 0;
        console.log('Name: ', this.name)
        console.log('MyUID: ', this.id)
        console.log('Room: ', this.room)
        this.myRef = firebase.database().ref(this.mixrId + '/' + this.id);
    }


    componentDidMount() {
        this.db.off()

        this.db.on('value', (snapshot) => {
            var data = snapshot.val()
            let rooms = {}
            !!data && Object.keys(data).forEach(item => {

                if (!rooms[data[item].room]) {
                    rooms[data[item].room] = []
                    rooms[data[item].room].push(data[item])
                } else {
                    rooms[data[item].room].push(data[item])
                }

                if (data[item].id === this.id) {
                    if (this.room !== data[item].room) {
                        this.joinCall(data[item].room)
                    }
                }


            })
            this.setState({ rooms: rooms })
        })

        this.myRef.onDisconnect().set(null)

        this.myRef.set({
            name: this.name,
            id: this.id,
            img: this.img,
            room: this.room,
            date: Date.now()
        });


    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    randRoomNumber() {
        // return Math.floor(1000000 + Math.random() * 9000000);
        return Date.now();
    }

    newRoom(room, isNewRoom = 0) {

        if (room !== this.roomId) {
            this.roomId = room
            this.myRef.set({
                name: this.name,
                id: this.id,
                img: this.img,
                room: room,
                date: Date.now(),
            });

            if (isNewRoom) this.setState({ newRoomNum: this.randRoomNumber() })
        }


    }

    joinCall() {

    }


    render() {
        var roomsUnordered = this.state.rooms
        var rooms = Object.keys(roomsUnordered).sort().reduce(
            (obj, key) => {
                obj[key] = roomsUnordered[key];
                return obj;
            },
            {}
        );
        return (
            <div className='roomsPage'>
                <VideoRoom id={this.id} />
                <h1>Welcome to Mixr! Click to jump between rooms!</h1>
                {Object.keys(rooms).map((key) => {
                    console.log(rooms[key])
                    return (
                        <Room key={key} newRoom={this.newRoom} roomId={key} users={rooms[key]} theme={themes[key % themes.length]} />
                    );
                })}
                <Room roomId={'New'} newRoom={this.newRoom} newRoomNum={this.state.newRoomNum} theme={themes[this.state.newRoomNum % themes.length]} />
            </div>
        )
    }
}





