import React from 'react'
import '../styles/styles.scss'

export default function User({ user }) {
    if (!!user) {
        return (
            <div className='user' key={user.id}>
                <img src={user.img} />
                <p>{user.name}</p>
            </div>
        )
    } else {
        return null
    }
}
