import { useMeetingManager, VideoTileGrid, useLocalVideo } from 'amazon-chime-sdk-component-library-react';

import React, { useState } from 'react'

export default function VideoRoom({ id }) {
    const meetingManager = useMeetingManager();
    const [joinedMeeting, setJoinedMeeting] = useState(0)

    const joinMeeting = async () => {
        // Fetch the meeting and attendee data from your server
        const response = await fetch(`http://172.105.20.95:6969/?_id=${id}`);
        const data = await response.json();
        console.log(data)
        const joinData = {
            meetingInfo: data.meeting_data,
            attendeeInfo: data.attendee,
        };

        // Use the join API to create a meeting session
        await meetingManager.join(joinData);

        // At this point you can let users setup their devices, or start the session immediately
        await meetingManager.start();
        setJoinedMeeting(1)

    };

    const { toggleVideo } = useLocalVideo();

    return <>
        {
            joinedMeeting ?
                <>
                    <button onClick={toggleVideo}>share video</button>
                    <VideoTileGrid noRemoteVideoView={<div>No one is sharing his video</div>} />
                </> : <button onClick={joinMeeting}>Join</button>
        }
    </>;
}


